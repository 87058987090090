import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/AuthContext';
import { useConfig } from 'context/ConfigContext';

import { useNavigate } from 'react-router-dom';

import InputMask from 'react-input-mask';

import { AzureProfile } from 'sso/azure/ui/AzureProfile';
import { getAzureToken } from 'sso/azure/azure-api';
import './styles.scss';
import { setStorageItem } from 'utils/storage';
import { actionLog } from 'services/actionlog.service';
import { type SubmitHandler, useForm } from 'react-hook-form';
import LogoSecundary from 'components/LogoSecundary';
import Logo from 'components/Logo';

type InputsForm = {
  username: string;
  password: string;
};

const Login = () => {
  const { auth } = useConfig();
  const { loginWithToken, login, isAuthenticated, DateDifferenceCheck } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const [useSSO, setUseSSO] = useState(false);
  const {
    register,
    handleSubmit: submit,
    watch,
    formState: { errors },
    reset,
  } = useForm<InputsForm>();

  const handleSubmit: SubmitHandler<InputsForm> = async data => {
    if (loading) return;
    doLogin(data.username, data.password);
  };

  const doLogin = useCallback(
    async (username: string, password: string) => {
      setLoading(true);

      try {
        await login({ username, password });
        actionLog('login');
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);
      }
    },
    [login],
  );

  useEffect(() => {
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  function loginSSO() {
    setLoading(true);
    setUseSSO(true);
    setInvalidUser(false);
  }

  const redirectUser = useCallback(() => {
    setTimeout(() => {
      DateDifferenceCheck();
    }, 300);
  }, [DateDifferenceCheck]);

  async function handleLoginSSO(response: any) {
    try {
      const password = await getAzureToken();
      const username = response.userPrincipalName;

      if (username && password) {
        await login({ username, password });
        redirectUser();
      }
    } catch (e) {
      setLoading(false);
      setInvalidUser(true);
      setUseSSO(false);
    }
  }

  useEffect(() => {
    async function doLogin(username: string, password: string) {
      try {
        await login({ username, password });
        redirectUser();
      } catch (e: any) {
        setLoading(false);
        setInvalidUser(true);

        if (
          e?.statusCode === 401 &&
          e?.data?.output?.length > 0 &&
          e.data.output[0] === 'Your login is disabled, contact the administrator'
        ) {
          navigate(`/acesso-negado`);
        }
      }
    }

    async function doLoginWithToken(token: string) {
      try {
        await loginWithToken(token);
        redirectUser();
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);
      }
    }

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const agent = params.get('agent');
    const secret = params.get('secret');
    const tokenItem = params.get('tokenItem');

    if (token) {
      setLoading(true);
      doLoginWithToken(token);
    } else if (agent && secret) {
      setLoading(true);
      doLogin(agent, secret);
    } else if (tokenItem) {
      setLoading(true);
      const decodedToken = atob(tokenItem);
      const tokenParts = decodedToken.split(':');
      const id = tokenParts[0];
      const password = tokenParts[1];

      setStorageItem('readOnly', 'true');
      doLogin(id, password);
    } else {
      setInvalidUser(true);
    }
  }, [loginWithToken, login, redirectUser, navigate]);

  const validarCPF = (cpf: string) => {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove a pontuação
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return 'CPF inválido';
    }

    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return 'CPF inválido';

    soma = 0;
    for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return 'CPF inválido';

    return true;
  };

  return (
    <div className="flex flex-col flex-center justify-center relative bg-blue h-screen gap-16 lg:gap-0 items-stretch">
      <div className="w-80 lg:ml-96 ml-24 hidden md:inline">
        <Logo />
      </div>
      <div className="md:h-[90%] h-[70%] bg-white absolute md:w-[480px] w-[390px] lg:right-[20%] md:right-24 right-3 flex flex-col items-center rounded-xl">
        <div className="w-[260px] relative md:mb-12 mt-4">
          <LogoSecundary />
        </div>

        <div className="my-4 py-9 px-6 border-t border-black w-full">
          <p className="text-black font-normal mx-8 text-lg" tabIndex={0}>
            <b className="text-3xl"> Olá!</b>
            <br />
            Aqui você poderá aprender muito.
            <br />
            Comece preenchendo seu CPF!
          </p>
        </div>
        {loading && !useSSO && !invalidUser && (
          <>
            <div className="cta">
              <div className=" hover:ring hover:ring-white rounded-md shadow-xl font-bold px-4 w-full text-xl p-2 bg-yellow-100 text-gray-300">
                <span>CARREGANDO...</span>
              </div>
            </div>
          </>
        )}

        {!loading && invalidUser && auth.authentication.auto && (
          <>
            <form className="md:w-96 w-80 block" onSubmit={submit(handleSubmit)} noValidate>
              <ul>
                <InputMask
                  mask="999.999.999-99"
                  maskChar=""
                  className="w-full border p-4 rounded-full border-blue text-black"
                  type="text"
                  id="username"
                  disabled={loading}
                  placeholder="000.000.000-00"
                  {...register('username', {
                    required: 'Campo obrigatório.',
                    validate: validarCPF,
                  })}
                />
                {errors.username && (
                  <span role="alert" className="text-red mt-2">
                    {errors.username.message}
                  </span>
                )}
              </ul>
              <p className="text-gray-500 mt-4">Apenas número sem pontuação</p>

              <button
                type="submit"
                disabled={loading}
                className="flex mt-8 items-center justify-center w-full  h-10 bg-blue rounded-full text-white font-medium uppercase border border-blue"
              >
                ACESSAR
              </button>
            </form>
          </>
        )}

        {useSSO ? <AzureProfile onAuthenticate={handleLoginSSO} /> : null}
      </div>
    </div>
  );
};

export default Login;
