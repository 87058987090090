import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import 'tippy.js/dist/tippy.css';
import 'rsuite/dist/rsuite.min.css';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { createMsalConfig } from './sso/azure/authConfig';

export let msalInstance: any;

async function initializeApp() {
  try {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(<div>Carregando...</div>);

    const config = await axios.post(
      `${process.env.REACT_APP_API_URL}/find/config`,
      {},
      {
        headers: {
          Authorization: `Basic NjZlZGFkYzE5YjQzMmE0ODJjMjcxNmRhOjY2ZjMzYTdiOWI0MzJhNDgyYzI3N2MzYw==`,
        },
      }
    );

    const configData: any = Array.isArray(config.data) && config.data.length ? config.data[0] : {};

    if (configData?.auth?.authentication?.sso_ms) {
      // Construct msalConfig using configData
      const msalConfig = createMsalConfig(configData?.auth?.authentication?.sso_ms_clientId, configData?.auth?.authentication?.sso_ms_tenantId, configData?.auth?.authentication?.sso_ms_url);

      msalInstance = new PublicClientApplication(msalConfig);

      // Account selection logic is app dependent. Adjust as needed for different use cases.
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }

      msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult;
          const account = payload.account;
          msalInstance.setActiveAccount(account);
        }
      });

      root.render(<App pca={msalInstance} configData={configData} />);
    } else {
      root.render(<App configData={configData} />);
    }

    // Start measuring performance
    reportWebVitals();
  } catch (error: any) {
    console.error('Erro durante a inicialização da aplicação:', error);

    // Tratamento de erros específicos do Axios
    if (error.response) {
      console.error('Erro na resposta da API:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('Nenhuma resposta recebida:', error.request);
    } else {
      console.error('Erro ao configurar a requisição:', error.message);
    }

    // Exibir mensagem de erro para o usuário
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(<div>Erro ao carregar a aplicação. Por favor, tente novamente mais tarde.</div>);
  }
}

// Call the initialize function
initializeApp();
