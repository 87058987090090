const BB_ROUTES: any = {
  HOME: () => '/',
  LOGIN: () => '/login',
  NOT_ALLOWED: () => '/acesso-negado',
  TRAIL: () => '/trilha',
  // IDEIAS: () => '/ideias',
  IDEIAS_INTERNA: (_id?: string) => `/ideias/${_id ? _id : ':id'}`,
  MODULE: (_id?: string) => `/modulo/${_id ? _id : ':id'}`,
  BOARD: (_id?: string) => `/tabuleiro/${_id ? _id : ':id'}`,
  CHALLENGE: () => `/desafios`,
  CHALLENGE_VIEW: (_id?: string) => `/desafio/${_id ? _id : ':id'}`,
  NOTIFICATION: () => `/notification`,
  STORE: () => `/loja`,
  RULE: () => `/regulamento`,
  EXTRATO: () => `/extrato`,
  BADGES: () => `/badges`,
  ADMINISTRATION: () => `/administration`,
  // RANKINGCONHECIMENTO: () => `/ranking-conhecimento`,
  // RANKINGCOMUNIDADE: () => `/ranking-comunidade`,
};

export default BB_ROUTES;
